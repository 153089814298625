const data = [
  {
    name: 'COSMIN VOISAN',
    title: 'CO-FOUNDER & PROJECT LEAD',
    description:
      'Cosmin is a Business Development Manager with extensive experience in Sales, Art and Cryptocurrency.',
    image: 'https://artetra.io/wp-content/uploads/2022/07/people-cosmin-voisan-1.webp',
    link: 'https://nl.linkedin.com/in/cosmin-voisan-8551851b',
  },
  {
    name: 'ZENO ARDELEAN',
    title: 'CO-FOUNDER & LEAD CREATIVE ARCHITECT',
    description:
      'Zeno is an experienced architect, in both the physical and virtual word. With over 15 years experience designing and delivering high end projects.',
    image: 'https://artetra.io/wp-content/uploads/2022/07/people-zeno-ardelean-1.webp',
    link: 'https://ro.linkedin.com/in/zeno-ardelean-25195246',
  },
  {
    name: 'THOMAS SEROPIAN',
    title: 'LEAD SOFTWARE ENGINEER',
    description:
      'Thomas is a software engineer with 15 years of experience in building technology solutions across several industries.',
    image: 'https://artetra.io/wp-content/uploads/2022/07/people-thomas-seropian-1.webp',
    link: 'https://uk.linkedin.com/in/tseropian',
  },
  {
    name: 'IOSIF VIGH',
    title: 'SOFTWARE ENGINEER',
    description:
      'Iosif has 10 years experience in web applications. He is an open source contributor and has actively invested in crypto since 2016.',
    image: 'https://artetra.io/wp-content/uploads/2022/07/people-iosif-vihg-1.webp',
    link: 'https://uk.linkedin.com/in/iosifv',
  },
  {
    name: 'ALEX MESESAN',
    title: '3D DEVELOPMENT LEAD',
    description:
      'Alex has over 15 years experience with 3D Software and 5 years experience with webGL and font-end development.',
    image: 'https://artetra.io/wp-content/uploads/2022/07/people-alex-mesesan.webp',
    link: 'https://www.linkedin.com/in/alexandru-mese%C8%99an-5b9101118/',
  },
  {
    name: 'ADRIAN BULAI',
    title: '3D MODELER',
    description:
      'Adi studied architecture and is a proficient 3d modeller, familiar with multiple softwares, techorientated and with an eye for aesthetics.',
    image: 'https://artetra.io/wp-content/uploads/2022/07/people-adrian.webp',
    link: 'https://www.linkedin.com/in/adrian-bulai-077501124/',
  },
];

export default data;
